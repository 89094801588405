@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Arial";
  src: url("/src/assets/fonts/arial.ttf") format("truetype");
}
html,
body {
  overflow-x: hidden;
}
/* * {
  cursor: url("./assets/cursor/cursor.cur"), auto !important;
} */

/* ::selection { */
/* background: #39b085; */
/* } */
body::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: transparent;
  /* color of the tracking area */
}
.container {
  overflow: visible;
}
html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar-thumb {
  background-color: #39b085;
}
@font-face {
  font-family: "Tatishvili";
  src: url("/src/assets/fonts/tatishvili.ttf") format("truetype");
}

@font-face {
  font-family: "Gordeziani";
  src: url("/src/assets/fonts/gordeziani.ttf") format("truetype");
}

@font-face {
  font-family: "Quadrosquare";
  src: url("/src/assets/fonts/quadrosquare.ttf") format("truetype");
}

body,
* {
  font-family: "Arial";
}

.h1_font_ka {
  font-family: "Tatishvili" !important;
}

.h2_font_ka {
  font-family: "Gordeziani" !important;
}

.nav_font_ka {
  font-family: "Quadrosquare" !important;
}
.main_font_ka {
  font-family: "Arial" !important;
}

.scroll-watcher {
  height: 5px;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: #3bb085;
  width: 100%;
  scale: 0 1;
  transform-origin: left;
  animation: scroll-watcher linear;
  animation-timeline: scroll();
}

@keyframes scroll-watcher {
  to {
    scale: 1 1;
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 640px) {
  .max_width {
    width: 100% !important;
  }
  .smallerTxt {
    font-size: 1.4rem;
    padding: 0;
    margin: 0;
  }
  .ml0 {
    margin-left: 0 !important;
  }
}
