.projects_glare {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  background: linear-gradient(
    0deg,
    #000000 0%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}

.project_info {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

.container:hover .projects_glare,
.container:hover .project_info {
  opacity: 1;
}


.container {
  cursor: pointer;
}
